import { useNavigate, useParams } from "react-router-dom";
import { Box } from "../../components";

import { Button, LinearProgress, Link, Tooltip, Typography } from "@mui/joy";
import { useGetCoursesByIdQuery } from "../../store/api/umbraco-api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useCallback, useMemo, useState } from "react";

import ExerciseFilterPicker from "./components/exercise-filter-picker";
import BasicModal from "../../components/basic-modal";
import { AutoAwesome, BubbleChart, Insights } from "@mui/icons-material";
import ColorfulElementCard from "./components/colorful-element-card";
import { FeatureType } from "./components/feature-info";
import { isEmpty } from "lodash/fp";
import { userSelector } from "../../store/session/selectors";
import { useSelector } from "react-redux";
import { some } from "lodash/fp";
import ConditionalTooltip from "../../components/conditional-tooltip";
import RichText from "../../components/rich-text";

const BGColors = ["#F390C6", "#53EC8A", "#FFF38B", "#81E6E5"];

const CourseContent = () => {
	const navigate = useNavigate();
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const user = useSelector(userSelector);

	const { courseId = "" } = useParams();
	const {
		data: course,
		isError,
		error,
		isLoading,
	} = useGetCoursesByIdQuery({
		id: parseInt(courseId || "0"),
	});

	const smartLessonsLinkShown = useMemo(
		() =>
			!isEmpty(
				course?.features?.filter(
					({ type }) => (type as unknown) === FeatureType.SmartLesson
				)
			),
		[course]
	);
	const exercisesLinkShown = useMemo(() => {
		const exercises = course?.features?.filter(
			({ type }) => (type as unknown) === FeatureType.Exercise
		);

		return !isEmpty(exercises);
	}, [course]);

	const exercisesLinkLocked = useMemo(() => {
		const exercises = course?.features?.filter(
			({ type }) => (type as unknown) === FeatureType.Exercise
		);

		return !some(["isLocked", false], exercises);
	}, [course]);

	const navigateTo = useCallback(
		(to: string) => () => navigate(to),
		[navigate]
	);

	if (isError) {
		if ((error as FetchBaseQueryError)?.status === 404) {
			navigate("/404");
		}
	}

	if (isLoading) {
		return <LinearProgress sx={{ width: "600px" }} />;
	}

	return (
		<Box
			display="flex"
			justifyContent="flex-start"
			alignItems="flex-start"
			flexDirection="column"
			flexShrink={1}
			sx={{ gap: 1, maxWidth: "1000px" }}
		>
			<BasicModal
				title="תרגול לפי נושאים"
				open={filterModalOpen}
				onClose={() => setFilterModalOpen(false)}
			>
				<ExerciseFilterPicker />
			</BasicModal>

			<Box>
				<Typography sx={{ paddingBottom: 2 }} level="h1">
					{course?.name}
				</Typography>
				{course?.name === "משפיעים בלימודים" ? (
					<>
						<Typography level="body-sm">
							הקורס נוצר ומנוהל על ידי{" "}
							<Link href="https://rothschildcp.com/">
								עמותת שותפויות אדמונד דה רוטשילד
							</Link>{" "}
							וסטארט-אפ האימפקט TAUGA AI
						</Typography>
					</>
				) : (
					<>
						{course?.shortDescription && (
							<Typography>
								<RichText content={course.shortDescription} />
							</Typography>
						)}
					</>
				)}
			</Box>

			{user?.isTeacher && (
				<Button onClick={navigateTo("stats")}>איזור ניהול</Button>
			)}

			{smartLessonsLinkShown && exercisesLinkShown && (
				<Typography level="title-lg">למידה</Typography>
			)}

			<Box
				display="flex"
				justifyContent="flex-start"
				alignItems="flex-start"
				flexDirection="row"
				flexShrink={1}
				sx={{ gap: 3, flexWrap: "wrap", paddingBottom: 3 }}
			>
				{smartLessonsLinkShown && (
					<ColorfulElementCard
						label="שיעורים חכמים"
						bgcolor={BGColors[1]}
						iconComponent={AutoAwesome}
						onClick={navigateTo(`/course/${courseId}/smart-lessons`)}
					/>
				)}

				{/* <ColorfulElementCard
          label="תרגול נושאים"
          onClick={() => setFilterModalOpen(true)}
          bgcolor={BGColors[2]}
          iconComponent={Checklist}
        /> */}

				{exercisesLinkShown && (
					<ConditionalTooltip
						visible={exercisesLinkLocked}
						arrow
						placement="left"
						title="ייפתח בהמשך!"
					>
						<Box>
							<ColorfulElementCard
								onClick={navigateTo(`/course/${courseId}/exercises`)}
								label="תרגול בינה מלאכותית"
								bgcolor={BGColors[1]}
								iconComponent={Insights}
								isLocked={exercisesLinkLocked}
							/>
						</Box>
					</ConditionalTooltip>
				)}
			</Box>

			{!user?.isTeacher && (
				<Tooltip arrow placement="left" title="התוכן יהיה זמין בקרוב!">
					<Box>
						<Typography level="title-lg" sx={{ paddingBottom: 1 }}>
							איזור אישי
						</Typography>
						<ColorfulElementCard
							disabled
							label="סטטיסטיקה"
							onClick={navigateTo(`/course/${courseId}/statistics`)}
							bgcolor={BGColors[3]}
							iconComponent={BubbleChart}
							isLocked={true}
						/>
					</Box>
				</Tooltip>
			)}
		</Box>
	);
};

export default CourseContent;
