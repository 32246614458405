import { createSlice } from "@reduxjs/toolkit";
import { User } from "./session.types";

import { login } from "./actions/user.actions";

export interface SessionState {
  user?: User;
  currentOrganizationId?: string;
}

const initialState: SessionState = {};

const addUser = (state: SessionState, action: ReturnType<typeof login>) => {
  return { ...state, user: action.payload };
};

const logout = (state: SessionState) => {
  return initialState;
};

const setCurrentOrganizationId = (
  state: SessionState,
  action: { payload: string }
) => {
  state.currentOrganizationId = action.payload;
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    login: addUser,
    logout,
    "set-current-organization": setCurrentOrganizationId,
    PURGE: logout,
  },
});

export default sessionSlice.reducer;
