import {
  Box,
  Button,
  Card,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/joy";
import {
  AvTimer,
  ChecklistRtl,
  Diversity3,
  FileOpen,
} from "@mui/icons-material";
import ColorfulElementCard from "../components/colorful-element-card";
import { IconComponentProp } from "../../../components/icon";
import ContentUnitsGraph from "./consumed-content-units";
import StudentList, { UserStat } from "./student-list";
import { umbracoApi } from "../../../store/api/umbraco-api";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { REACT_APP_SERVER_DOMAIN } from "../../../base-env";

interface Stats {
  userStats: UserStat[];
  questionStats: [
    {
      categoryId: number;
      type: string;
      question: { description: string; numberOfOptions: number };
      successRatePercentage: [];
      timesViewed: number;
    }
  ];
}

const Statistic = ({
  label,
  value,
  color,
  iconComponent,
}: {
  label: string;
  value: string;
  color: string;
  iconComponent: IconComponentProp;
}) => {
  return (
    <Box>
      <ColorfulElementCard
        bgcolor={color}
        iconComponent={iconComponent}
        label={label}
        containerStyle={{ width: 50, height: 35 }}
        iconStyle={{ fontSize: 20 }}
        fontSize={12}
        mainText={value}
      />
    </Box>
  );
};

const CourseAdmin = () => {
  const { courseId = "" } = useParams();
  const [fetch, { data, isLoading }] =
    umbracoApi.useLazyGetStatsCourseByCourseIdQuery();

  useEffect(() => {
    fetch({
      courseId: parseInt(courseId),
    });
  }, [fetch]);

  const exportStats = useCallback(() => {
    window.open(
      `${REACT_APP_SERVER_DOMAIN}stats/export/course/${courseId}`,
      "_blank"
    );
  }, [courseId]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!data) {
    return <>בעיה</>;
  }

  return (
    <Box gap={3} display="flex" flexDirection="column" width={800}>
      <Typography level="title-lg">סטטיסטיקות קורס</Typography>
      <Box gap={1} display="flex" flexDirection="row" alignItems="center">
        <Statistic
          iconComponent={Diversity3}
          color="#0000ff33"
          label="תלמידים רשומים לקורס"
          value={(data as Stats).userStats.length.toString()}
        />
        <Statistic
          iconComponent={ChecklistRtl}
          color="#d4ff0033"
          label="יחידות תוכן שבוצעו"
          value="1200"
        />
        <Statistic
          iconComponent={AvTimer}
          color="#ff009033"
          label="שעות לימוד"
          value="80"
        />
      </Box>
      <Divider />
      {/* <Card sx={{ height: 200, width: 800 }}>
        <Typography level="title-md">
          רמת פתיחה ממוצעת של תלמידים לעומת רמה נוכחית
        </Typography>

        <StudentLevelGraph />
      </Card> */}
      <Card sx={{ height: 200, width: 800 }}>
        <Typography level="title-md">יחידות תוכן שנצרכו </Typography>

        <ContentUnitsGraph />
      </Card>
      <Divider />
      <Typography>תלמידים</Typography>
      {data && (data as { userStats: [UserStat] })?.userStats ? (
        <StudentList
          userStats={(data as { userStats: [UserStat] }).userStats}
        />
      ) : null}

      <Button variant="outlined" sx={{ width: 150 }} onClick={exportStats}>
        ייצא לאקסל
        <FileOpen />
      </Button>
    </Box>
  );
};

export default CourseAdmin;
