import { Box, Button, Card, Input } from "../components";
import { umbracoApi, usePostAuthLoginMutation } from "../store/api/umbraco-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { login } from "../store/session/actions/user.actions";
import LogoBox from "../components/header/logo-box";
import { CircularProgress, Typography } from "@mui/joy";
import { get } from "lodash/fp";
import { CUSTOMERS_LOGOS } from "../consts";
import { TAUGA_AI_LOGO_1 } from "../images/images";

const Login = () => {
	const dispatch = useDispatch();
	const [mutate, { isSuccess, isLoading, data, isError }] =
		usePostAuthLoginMutation();
	const [fetchOrgs, { data: orgsData, isLoading: isLoadingOrgs }] =
		umbracoApi.useLazyGetOrganizationsQuery();
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const navigate = useNavigate();
	const { provider } = useParams(); // Get the provider from the URL params
	const [logoToDisplay, setLogoToDisplay] = useState(CUSTOMERS_LOGOS.default); // State for dynamic logo

	useEffect(() => {
		if (provider) {
			const normalizedProvider = provider.toLowerCase();
			if (CUSTOMERS_LOGOS[normalizedProvider]) {
				setLogoToDisplay(CUSTOMERS_LOGOS[normalizedProvider]);
			} else {
				setLogoToDisplay(CUSTOMERS_LOGOS.default); // Fallback to default logo if provider not found
			}
		} else {
			setLogoToDisplay(CUSTOMERS_LOGOS.default); // Fallback to default logo if no provider
		}
	}, [provider]);

	const onLogin = () => {
		mutate({
			loginRequest: {
				username: email,
				keepMeLoggedIn: true,
				password,
			},
		});
	};

	useEffect(() => {
		if (isSuccess && data) {
			const { email, firstName, dateOfBirth, lastName } = data;

			if (firstName && email && lastName && dateOfBirth) {
				dispatch(
					login({
						email,
						firstName,
						lastName,
						dateOfBirth,
						isTeacher: get("isTeacher", data) || false,
					})
				);

				fetchOrgs();
			}
		}
	}, [data, dispatch, isSuccess, fetchOrgs]);

	useEffect(() => {
		if (orgsData) {
			const firstCourse = get([0, "courses", 0, "id"], orgsData);

			if (firstCourse) {
				navigate(`/course/${firstCourse}`);
			}
		}
	}, [orgsData, navigate]);

	return (
		<Box
			width={200}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				width={200}
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					gap: 1,
					paddingBottom: 2,
				}}
			>
				{/* Display the dynamic logo based on the provider */}
				<LogoBox imageSrc={logoToDisplay} imageHeight={70} />
				<LogoBox imageSrc={TAUGA_AI_LOGO_1} imageHeight={70} />
			</Box>
			<Card size="lg">
				<Input
					disabled={isLoading || isLoadingOrgs}
					value={email}
					onChange={(v) => setEmail(v.target.value)}
					placeholder="אימייל"
					type="email"
				/>
				<Input
					disabled={isLoading || isLoadingOrgs}
					value={password}
					onChange={(v) => setPassword(v.target.value)}
					type="password"
					placeholder="סיסמה"
				/>
				<Button
					disabled={!email || !password || isLoading || isLoadingOrgs}
					onClick={onLogin}
				>
					{!isLoading || !isLoadingOrgs ? "התחבר" : <CircularProgress />}
				</Button>
			</Card>

			{isError && (
				<Box>
					<Typography>
						שם המשתמש או הסיסמה שגויים, אנא נסה שוב או שלח אימייל ל
						Idan@Tauga.ai
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default Login;
