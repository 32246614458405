import { useNavigate } from "react-router";
import { Box } from "../components";
import Typography from "../components/typography";
import {
  Course,
  Organization,
  useGetOrganizationsQuery,
} from "../store/api/umbraco-api";
import { useDispatch } from "react-redux";
import { setCurrentOrganization } from "../store/session/actions/organization.actions";
import { Card, CardContent, LinearProgress } from "@mui/joy";
import ButtonBase from "@mui/material/ButtonBase";

interface RenderOrgProps extends Organization {
  color: string;
}

const RenderOrg = ({ courses, name, color }: RenderOrgProps) => {
  const dispatch = useDispatch();

  if (!courses) {
    return null;
  }

  return (
    <Box>
      <Typography
        pb={2}
        onClick={() => dispatch(setCurrentOrganization(name || ""))}
        level="h2"
      >
        {name}
      </Typography>
      <Box gap={2} display="flex" flexDirection="row">
        {courses.map((course, index) => (
          <RenderCourse key={course.id} {...course} color={color} />
        ))}
      </Box>
    </Box>
  );
};

const CourseSquareSize = 100;

const BGColors = ["#53EC8A", "#81E6E5", "#F390C6", "#FFF38B"];

interface RenderCourseProps extends Course {
  color: string;
}

const RenderCourse = ({ id, name, color }: RenderCourseProps) => {
  const navigate = useNavigate();

  return (
    <ButtonBase onClick={() => navigate(`/course/${id}`)}>
      <Card
        sx={{
          bgcolor: color,
          maxHeight: CourseSquareSize,
          maxWidth: CourseSquareSize,
          minHeight: CourseSquareSize,
          minWidth: CourseSquareSize,
          border: "1px solid black",
          "&:hover": {
            boxShadow: "0px 7px 6.2px -3px rgba(135, 211, 183, 0.7)",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography fontSize={12} level="h4">
            {name}
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

const Home = () => {
  const { data, isLoading } = useGetOrganizationsQuery();

  if (isLoading) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  if (!data) {
    return null;
  }

  return (
    <Box sx={{ gap: 3, display: "flex", flexDirection: "column" }}>
      {data.map((org, index) => (
        <RenderOrg
          color={BGColors[index % BGColors.length]}
          {...org}
          key={org.name}
        />
      ))}
    </Box>
  );
};

export default Home;
