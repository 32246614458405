import { useDispatch, useSelector } from "react-redux";
import { Box } from "..";
import { Person } from "@mui/icons-material";

import Avatar from "../avatar";
import LogoBox from "./logo-box";
import { persistor } from "../../store";
import { Divider, Dropdown, Menu, MenuItem, Typography } from "@mui/joy";
import { MenuButton } from "@mui/joy";
import { logout } from "../../store/session/actions/user.actions";
import { usePostAuthLogoutMutation } from "../../store/api/umbraco-api";
import { useNavigate } from "react-router-dom";
import { userSelector } from "../../store/session/selectors";
import { ROTCHILD_LOGO_1, TAUGA_AI_LOGO_1 } from "../../images/images";

const Header = () => {
  const user = useSelector(userSelector);

  const [mutate] = usePostAuthLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onPressLogout = async () => {
    try {
      await mutate().unwrap();
      dispatch(logout());
      persistor.purge();
      navigate("login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      position={"relative"}
      padding={"15px"}
      height={55}
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom="1px solid lightgray"
    >
      <Box sx={{ flexDirection: "row", display: "flex", gap: 2 }}>
        <LogoBox imageSrc={TAUGA_AI_LOGO_1} />
        <LogoBox imageSrc={ROTCHILD_LOGO_1} />
      </Box>

      {user && (
        <Dropdown>
          <MenuButton
            slots={{ root: Avatar }}
            slotProps={{ root: { color: "neutral" } }}
          >
            <Avatar color="primary">
              <Person />
              {/* {get(["firstName", 0], user).toString()}
              {get(["lastName", 0], user).toString()} */}
            </Avatar>
          </MenuButton>
          <Menu sx={{ textAlign: "center" }}>
            <Typography level="body-xs">שלום {user.firstName}</Typography>
            <Divider />
            <MenuItem onClick={onPressLogout}>התנתק</MenuItem>
          </Menu>
        </Dropdown>
      )}
    </Box>
  );
};

export default Header;
