import { Navigate, Outlet } from "react-router-dom";
import Header from "../header";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/session/selectors";
import SideBar from "../sidebar";
import { Box } from "@mui/joy";

const AuthenticatedRoute = () => {
  const user = useSelector(userSelector);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Box height="100vh" display="flex" flexDirection={"column"}>
      <Header />
      <Box flexGrow={1} flex={1} flexDirection="row" display={"flex"}>
        <SideBar />
        <Box sx={{ flex: 1, padding: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthenticatedRoute;
