import { extendTheme } from "@mui/joy/styles";

export const themeBase = {
  components: {},
  colorSchemes: {
    light: {
      palette: {
        primary: {
          "50": "#f0fdf4",
          "100": "#dcfce7",
          "200": "#bbf7d0",
          "300": "#86efac",
          "400": "#4ade80",
          "500": "#22c55e",
          "600": "#16a34a",
          "700": "#15803d",
          "800": "#166534",
          "900": "#14532d",
          white: "#FFFFFF",
          black: "#000000",
          gray: "#BDBDBD",
          green: "#22C55D",
          green_bright: "#53EC8A",
          green_very_bright: "#CDFFDF",
          blue_bright: "#81E6E5",
          gray_light: "#ECEBF0",
        },
      },
    },
    dark: {},
  },
};

export const theme = extendTheme(themeBase);
