import { useNavigate, useParams } from "react-router-dom";
import {
  umbracoApi,
  usePostFeaturesByFeatureIdMutation,
} from "../../../store/api/umbraco-api";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/joy";
import Unit from "../../../components/feature-units/unit";
import FeatureFinished from "./feature-finished";
import SimpleProgressBar from "../../../components/common/simple-progress-bar";

const Feature = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const [unitFinished, setUnitFinished] = useState(false);
  const [unitCompleteObject, setUnitCompleteObject] = useState({});

  const [fetch, { data, isFetching, isError }] =
    umbracoApi.useLazyGetFeaturesByFeatureIdQuery();
  const [mutate, { isSuccess: isPostSuccess }] =
    usePostFeaturesByFeatureIdMutation();

  const postUnitComplete = useCallback(() => {
    mutate({
      featureId: featureId as unknown as number,
      iUnitCompleted: unitCompleteObject,
    });
  }, [featureId, mutate, unitCompleteObject]);

  useEffect(() => {
    fetch({ featureId: parseInt(featureId as string) });
  }, [featureId, fetch, navigate]);

  useEffect(() => {
    if (isPostSuccess) {
      fetch({ featureId: parseInt(featureId as string) });
    }
  }, [isPostSuccess]);

  if (isError) {
    navigate("/404");
  }

  if (isFetching) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  if (!data || !data.feature) {
    return <Typography>שגיאה בלתי צפויה</Typography>;
  }

  const { feature } = data;

  const { unitsCompleted = 0, unitsTotal = 1, status } = feature;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: "600px",
      }}
    >
      <SimpleProgressBar
        title="התקדמות בשיעור"
        tooltipText="האחוזים הינם הערכה בלבד, שכן אורך הקורס המדויק מתאים את עצמו אליך תוך כדי תרגול"
        value={unitsCompleted}
        total={unitsTotal}
      />
      {unitsCompleted === unitsTotal ||
      (status as unknown as number) === 2 ||
      !data.unit ? (
        <FeatureFinished />
      ) : (
        <>
          <Unit
            setUnitFinished={setUnitFinished}
            onUnitCompleteChange={setUnitCompleteObject}
            unit={data.unit}
          />
          {unitFinished && (
            <Button
              sx={{
                width: 150,
              }}
              onClick={postUnitComplete}
            >
              המשך
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default Feature;
