import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { CssVarsProvider } from "@mui/joy";

import { persistor, store } from "./store";

import UnauthenticatedRoute from "./components/route-guards/unauthenticated-route";
import AuthenticatedRoute from "./components/route-guards/authenticated-route";

import CourseContent from "./screens/course";
import Feature from "./screens/course/feature";
import Login from "./screens/login";
import Home from "./screens/home";
import { theme } from "./theme";
import NotFound from "./screens/not-found";
import SmartLessons from "./screens/course/course-content/smart-lessons.tsx";
import Exercises from "./screens/course/course-content/exercises";
import CourseAdmin from "./screens/course/admin";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const queryClient = new QueryClient({});

const router = createBrowserRouter([
  {
    element: <UnauthenticatedRoute />,
    children: [
      { path: "/login", element: <Login /> }, // Normal login route
      { path: "/login/:provider", element: <Login /> }, // Dynamic provider route
    ],
  },
  {
    element: <AuthenticatedRoute />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "course/:courseId",
        element: <CourseContent />,
      },
      {
        path: "course/:courseId/stats",
        element: <CourseAdmin />,
      },
      // {
      //   path: "course/:courseId/statistics",
      //   element: <StudentCourseStatistics />,
      // },
      {
        path: "course/:courseId/smart-lessons",
        element: <SmartLessons />,
      },
      {
        path: "course/:courseId/exercises",
        element: <Exercises />,
      },
      {
        path: "course/:courseId/feature/:featureId",
        element: <Feature />,
      },
      {
        path: "course/:courseId/exercise/:featureId",
        element: <Feature />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    ReactGA.initialize("G-VPV7SFBWV6");
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssVarsProvider theme={theme} />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
