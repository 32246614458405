import { Box, Typography } from "@mui/joy";
import { Course, useGetOrganizationsQuery } from "../../store/api/umbraco-api";

import { Home } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import SideBarNavButton from "./components/sidebar-nav-button";
import Section from "./components/section";
import { useMemo } from "react";
import { isEmpty } from "lodash";

const drawerWidth = 150;

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useGetOrganizationsQuery();

  const courses = useMemo(
    () =>
      data
        ? data.reduce((prev, curr, index) => {
            return prev.concat(...(curr.courses || []));
          }, [] as Course[])
        : [],
    [data]
  );

  return (
    <Box
      sx={{
        gap: 1,
      }}
      padding={3}
      minWidth={drawerWidth}
      borderLeft={"1px solid lightGray"}
      flexDirection={"column"}
      alignItems={"start"}
      display={"flex"}
    >
      <Section>
        <SideBarNavButton
          Icon={<Home fontSize="small" sx={{ ml: 1 }} />}
          text="בית"
          checked={location.pathname === `/home` || location.pathname === `/`}
          onClick={() => navigate(`home`)}
        />
      </Section>

      {!isEmpty(courses) && (
        <Section>
          <Typography level="body-xs">הקורסים שלי</Typography>
          {courses.map(({ id, name }, index) => (
            <SideBarNavButton
              key={id}
              checked={location.pathname.includes(`/course/${id}`)}
              text={name || ""}
              onClick={() => navigate(`/course/${id}`)}
            />
          ))}
        </Section>
      )}
    </Box>
  );
};

export default SideBar;
