import { useEffect, useState } from "react";
import { REACT_APP_SERVER_DOMAIN } from "../base-env";
import { Typography } from "@mui/joy";

interface ContentUnitProps {
	content: string;
}

const addImageSource = (content: string) => {
	const lookupText = '<img src="';
	const position = content.indexOf('<img src="');
	const changePosition = position + lookupText.length;
	const imageSource = REACT_APP_SERVER_DOMAIN;

	if (position > 0) {
		return (
			content.substring(0, changePosition) +
			imageSource +
			content.substring(changePosition)
		);
	}

	return content;
};

const RichText = ({ content }: ContentUnitProps) => {
	const [contentWithImages, setImages] = useState(content);

	useEffect(() => {
		setImages(addImageSource(content));
	}, [content]);

	return (
		<Typography>
			<div dangerouslySetInnerHTML={{ __html: contentWithImages }} />
		</Typography>
	);
};

export default RichText;
