import { ROTCHILD_LOGO_1, TAUGA_AI_LOGO_1 } from "../images/images";

// Define the interface for the customers' logos
interface CustomerLogos {
  [key: string]: string; // Or more specific if you have defined types, e.g., rothchild | tauga
}

// The object containing customer logos
export const CUSTOMERS_LOGOS: CustomerLogos = {
  rothschild: ROTCHILD_LOGO_1,
  tauga: TAUGA_AI_LOGO_1,
  default: ROTCHILD_LOGO_1,
};
