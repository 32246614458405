import { TooltipProps } from "@mui/joy";
import { Tooltip } from ".";

interface ConditionalTooltipProps extends TooltipProps {
  visible?: boolean;
}

const ConditionalTooltip = ({
  children,
  visible,
  ...rest
}: ConditionalTooltipProps) => {
  if (!visible) {
    return children;
  }
  return <Tooltip {...rest}>{children}</Tooltip>;
};

export default ConditionalTooltip;
