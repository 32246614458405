import { Box, Typography, Tooltip } from "@mui/joy";
import { EmojiEvents, InfoOutlined } from "@mui/icons-material";
import { themeBase } from "../../theme";

interface ProgressBarProps {
  title: string;
  value: number;
  total: number;
  tooltipText: string;
}

const SimpleProgressBar = ({
  value,
  total,
  tooltipText,
  title,
}: ProgressBarProps) => {
  const progress = (value / total) * 100;
  if (value > 0) {
    return (
      <Box sx={{ width: "100%", paddingBottom: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
            <Tooltip title={tooltipText}>
              <InfoOutlined sx={{ fontSize: 18 }} />
            </Tooltip>
          </Box>

          <Typography sx={{ fontWeight: "bold" }}>
            {progress.toFixed(0)}%
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "28px",
            borderRadius: "5px",
            position: "relative",
            overflow: "hidden",
            backgroundColor:
              themeBase.colorSchemes.light.palette.primary.gray_light,
          }}
        >
          <Box
            sx={{
              width: `${progress}%`,
              height: "100%",
              backgroundColor:
                themeBase.colorSchemes.light.palette.primary.green,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "50%",

              transform: "translateY(-50%)",
              left: "10px",
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmojiEvents
              fontSize="small"
              sx={{ justifySelf: "center", alignItems: "center" }}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export default SimpleProgressBar;
