import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { REACT_APP_SERVER_DOMAIN } from "../../base-env";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_SERVER_DOMAIN,
    credentials: "include",
    prepareHeaders(headers) {
      headers.set("Access-Control-Allow-Credentials", " true");
      return headers;
    },
  }),
  endpoints: () => ({}),
});
