import { Box, Sheet, Table } from "@mui/joy";
import { useState } from "react";

export interface UserStat {
  userInfo: { name: string; email: string; createdAt: string };
  level: number;
  learningMinutes: number;
  numOfUnits: number;
  stats: [
    {
      categoryId: number;
      topicId: number;
      topicLevel: number;
      learningMinutes: number;
      numOfQuestionsAnswered: {
        today: number;
        yesterday: number;
        lastWeek: number;
        lastMonth: number;
        ever: number;
      };
    }
  ];
}

const UserStatComp = ({
  userInfo: { name, email, createdAt },
  learningMinutes,
  numOfUnits,
  level,
  stats,
}: UserStat) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr onClick={() => setOpen((toggle) => !toggle)}>
        <td>{name}</td>
        <td>{email}</td>
        <td>{new Date(createdAt).toDateString()}</td>
        <td>{learningMinutes}</td>
        <td>{numOfUnits || 0}</td>
        <td>{level || "לא נקבעה"} </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={3} style={{ height: 0, padding: 0 }}>
            <Sheet
              variant="soft"
              sx={{
                p: 1,
                pl: 6,
                boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)",
              }}
            >
              <Box display="flex" flexDirection="row">
                {stats.map((stat) => (
                  <>{stat.numOfQuestionsAnswered.ever}</>
                ))}
                {/* <NumericDataCircle color="teal" number={level} title="רמה" />
                <NumericDataCircle color="teal" number={level} title="רמה" />
                <NumericDataCircle color="teal" number={level} title="רמה" /> */}
              </Box>
            </Sheet>
          </td>
        </tr>
      )}
    </>
  );
};

const StudentList = ({ userStats }: { userStats: UserStat[] }) => {
  return (
    <Table>
      <thead>
        <tr>
          <td style={{ verticalAlign: "top" }}>שם</td>
          <td style={{ verticalAlign: "top" }}>אימייל</td>
          <td style={{ verticalAlign: "top" }}>תאריך הצטרפות לקורס</td>
          <td style={{ verticalAlign: "top" }}>שעות למידה סה"כ</td>
          <td style={{ verticalAlign: "top" }}>מספר יחידות תוכן שצרך</td>
          <td style={{ verticalAlign: "top" }}>רמה כללית</td>
        </tr>
      </thead>
      <tbody>
        {userStats.map((userStat) => (
          <UserStatComp key={userStat.userInfo.email} {...userStat} />
        ))}
      </tbody>
    </Table>
  );
};

export default StudentList;
