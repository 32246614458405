import { ResponsiveBar } from "@nivo/bar";

const BAR_KEY = "יחידות תוכן";
const bardata = [
  {
    country: "הסטודנט המילואימניק",
    [BAR_KEY]: 80,
  },
  {
    country: "יישום AI בחיי הסטודנט",
    [BAR_KEY]: 24,
  },
  {
    country: "ראיונות עבודה",
    [BAR_KEY]: 68,
  },
  {
    country: "עמידה מול קהל",
    [BAR_KEY]: 144,
  },
  {
    country: "חסכונות והשקעות",
    [BAR_KEY]: 170,
  },
  {
    country: "קבלת החלטות",
    [BAR_KEY]: 18,
  },
  {
    country: "ניהול זמן",
    [BAR_KEY]: 20,
  },
];

const ContentUnitsGraph = () => {
  return (
    <ResponsiveBar
      data={bardata}
      keys={[BAR_KEY]}
      indexBy="country"
      margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={"#f67856"}
      animate={false}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
        renderTick: ({
          opacity,
          textAnchor,
          textBaseline,
          textX,
          textY,
          value,
          x,
          y,
        }) => {
          return (
            <g transform={`translate(${x},${y + 5})`} style={{ opacity }}>
              <text
                style={{ fontSize: 12, fill: "#626262" }}
                textAnchor={textAnchor}
                transform={`translate(${textX},${textY})`}
              >
                {value}
              </text>
            </g>
          );
        },
      }}
      axisLeft={null}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
    />
  );
};

export default ContentUnitsGraph;
