import { ButtonBase, SxProps } from "@mui/material";
import Icon, { IconComponentProp } from "../../../components/icon";
import { Box, Card, CardContent, Typography } from "@mui/joy";
import { PropsWithChildren } from "react";
import { Lock } from "@mui/icons-material";

const CourseSquareSize = 170;
const textColor = "#006062";

interface ColorfulElementCardProps {
  label: string;
  bgcolor: string;
  onClick?(): void;
  iconComponent: IconComponentProp;
  isLocked?: Boolean; // Make secondIcon optional
  containerStyle?: SxProps;
  iconStyle?: SxProps;
  fontSize?: number;
  mainText?: string;
  disabled?: boolean;
}

const ClickAbleContainer = ({
  onClick,
  children,
}: Pick<ColorfulElementCardProps, "onClick"> & PropsWithChildren) => {
  if (onClick) {
    return <ButtonBase onClick={onClick}>{children}</ButtonBase>;
  }
  return <>{children}</>;
};

const emptyFun = () => {};

const ColorfulElementCard = ({
  label,
  bgcolor,
  iconComponent,
  onClick,
  containerStyle,
  iconStyle,
  fontSize = 18,
  mainText,
  disabled,
  isLocked,
}: ColorfulElementCardProps) => {
  return (
    <ClickAbleContainer onClick={disabled || isLocked ? emptyFun : onClick}>
      <Card
        sx={{
          bgcolor,
          maxHeight: CourseSquareSize,
          maxWidth: CourseSquareSize,
          minWidth: CourseSquareSize,
          border: "1px solid black",
          "&:hover": {
            boxShadow: "0px 7px 6.2px -3px rgba(135, 211, 183, 0.7)",
          },
          ...containerStyle,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Icon
              component={iconComponent}
              sx={{ pt: 0.5, ml: 1, ...iconStyle }}
              htmlColor={textColor}
              fontSize="large"
            />
            {isLocked && <Lock htmlColor={textColor} fontSize="small" />}
            {mainText && (
              <Typography
                alignSelf="start"
                fontSize={18}
                level="title-lg"
                textColor={textColor}
              >
                {mainText}
              </Typography>
            )}
          </Box>

          <Typography
            alignSelf="start"
            fontSize={fontSize}
            level="body-md"
            textColor={textColor}
          >
            {label}
          </Typography>
        </CardContent>
      </Card>
    </ClickAbleContainer>
  );
};

export default ColorfulElementCard;
